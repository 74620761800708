import scales_gold from './img/scales_gold.png';
import { FormButton } from './ConsultationForm';

function WhatWeDo(props) {
  return (
    <div id='what-we-do'>
      <h2>What We Do</h2>
      <div className='list-mobile'>            
        {props.columns.map((content, i) => 
          content.map((c, j) => (
            <div key={j} className='row'>
              <img src={scales_gold} alt='' />
              <p className='roboto'>
                {c}
              </p> 
            </div>
          ))
        )}
      </div>
      <div className='list-desktop'>            
        {props.columns.map((content, i) => 
          <div key={i} className={`${props.columns.length > 1 ? 'multi' : ''} col`}>
            {content.map((c, i) => (
              <div key={i} className='row'>
                <img src={scales_gold} alt='' />
                <p className='roboto'>
                  {c}
                </p> 
              </div>
            ))}
          </div>
        )}
      </div>

      <div className='flex-col'>
      <FormButton type='consultation' text='SCHEDULE A CONSULTATION' />
      </div>
    </div> 
  )
 }

export default WhatWeDo;