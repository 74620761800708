import React from 'react';
import { IsTablet, IsMobile, IsLarge, renderClass } from './breakpoints';
import {createUseStyles} from 'react-jss';
import colors from './colors';
import { Link } from 'react-router-dom';
import { Button } from './ConsultationForm';

 function ScrollToTopOnMount() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

function getRelatedBlogs(blog, blogs) {
  return blogs;
  /* TODO: Not sure if this is really necessary
  let categories = blog.categories.split(',').map(category => category.trim());
  blogs = blogs.filter(b => {
    let c = b.categories.split(',').map(category => category.trim());
    return (b.path != blog.path && c.map(category => categories.indexOf(category) != -1).some(e => e));
  });
  return blogs;*/
}

export async function loadBlogs() {
  let organization = 'Watson Law'
  let state = this.state;
  state.isLoaded = true;
  try {
    let blogs = null; //localStorage.getItem('blogs');
    if (blogs) {
      blogs = JSON.parse(blogs);
    } else {
      let res = await fetch( `https://cloud.zora.digital:8080/api/blogs/public/organization/${organization}`);
      if (res.status !== 200) 
        throw Error('Could not find blog posts.');
      blogs =  await res.json();
      localStorage.setItem('blogs', JSON.stringify(blogs));
    }
    state.blogs = blogs;
  } catch (error) {
    console.log(error)
    state.blogs = [];
    state.error = error;
  }
  this.setState(state);
  //TODO: Should maybe store last time blogs were loaded, only reload if necessary? idk

}

function BlogTitle(props) {
  let isTablet = IsTablet();
  let isLarge = IsLarge();
  let isMobile = IsMobile();

  let fontSize = isMobile ? 12 : isTablet ? 16 : isLarge ? 22 : 16;
  let paddingTop = isMobile || isTablet ? 50 : isLarge ? 100 : 75;
  let paddingBottom = isMobile || isTablet ? 50 : isLarge ? 100 : 75;
  let hPadding = isTablet || isMobile ? '5%' : isLarge ? 100 : 75;
  let maxWidth = 1440;


  let blog = props.blogs[0];

  let imgUrl = `https://zora-blog.s3.us-east-2.amazonaws.com/${blog?.id}.webp`
  let imgHeight = isMobile ? 200 : 300;
  let imgWidth = isMobile ? 300 : 400;
  let flexClass = isMobile || isTablet ? 'flex-col aic' : 'flex-row';
  let textAlign = isMobile || isTablet ? 'center' : null;
  let blogWidth = isMobile || isTablet ? '80%' : null;
  let previewMaxwidth = isLarge ? 800 : 600;
 
  return (
    <div style={{background: '#090C43'}}>
    <div id='blog-preview' style={{maxWidth: 1440, margin: 'auto', position: 'relative', textAlign, fontSize, background: '#090C43', backgroundImage: 'url(/altpattern.png)', backgroundSize: `auto 90%`, paddingTop, paddingBottom, paddingLeft: hPadding, paddingRight: hPadding, backgroundPosition: `center bottom`, backgroundRepeat: 'no-repeat'}}>
      <div className='lora' style={{color: '#E19E39', fontSize: '2em', paddingBottom: '1em', maxWidth, textAlign: 'center', marginBottom: 30}}>
       Watson Law Blog
      </div>  
      {blog && 
        <div className={flexClass} style={{width: blogWidth, margin: 'auto', position: 'relative'}}>
          <img alt={blog.alt_tag} src={imgUrl} style={{height: imgHeight, width: imgWidth, objectFit: 'cover', marginRight: isMobile || isTablet ? 0 : 50, marginBottom: isMobile || isTablet ? 50 : 0}}/>
          <div>
            <div style={{ lineHeight: '1.5em', fontSize: '1.5em', maxWidth, margin: 'auto', color: 'rgba(253, 221, 173, 1)', fontWeight: 700}}>
              {blog.title}
            </div>
            <div style={{color: 'white', margin: '24px 0px', maxWidth: previewMaxwidth}}>
              {blog.text.slice(0, 250)}...
            </div>
            <Link to={`/blog/${blog.path}`} state={{blog, relatedBlogs: getRelatedBlogs(blog, props.blogs)}}><Button id='read-more' text='READ MORE' style={{position: isMobile || isTablet ? null : 'absolute', bottom: 0, display: 'inline-block'}}/></Link>
          </div>
        </div>
      }
    </div>  
    </div>
      
  );
}

const BlogGalleryStyles = createUseStyles({
  'tab-selected': { borderBottom: '6px solid #E19E39', },
  'tab': {borderBottom: '6px solid #F5F5F5'},
  'gallery-nav': {
    marginBottom: 25,
    overflowX: 'scroll',
    width: '100%',
    whiteSpace: 'nowrap',
    '& span': { whiteSpace: 'break-spaces', display: 'inline-block', paddingBottom: 20, paddingRight: 40, cursor: 'pointer'},
  },
})

class BlogGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tabIndex: 0, page: 1 };
  }

  render(){
    let classes = this.props.classes;
    let [ isTablet, isMobile, isLarge ] = [this.props.isTablet, this.props.isMobile, this.props.isLarge ];

    let hPadding = isTablet || isMobile ? '5%' : isLarge ? '100px' : '75px';
    let vPadding = isTablet || isMobile ? '30px' : isLarge ? '50px' : '30px';
    let gridTemplateColumns = isMobile ? 'repeat(1, 1fr)' :  isTablet ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)';
    let tabs = ['All Topics'];
    this.props.blogs.map(blog => {
      blog.categories.split(',').map(category => {
        if (tabs.indexOf(category.trim()) === -1 && category !== '') tabs.push(category.trim());
        return null;
      })
      return null;
    })
    let category = tabs[this.state.tabIndex];
    let blogs = this.props.blogs.filter(blog => (blog.categories.split(',').indexOf(category) !== -1 || category === 'All Topics'));
    return (
      <div id='blog-list' style={{padding: `${vPadding} ${hPadding}`, maxWidth: 1440, margin: 'auto'}}>
        <div id='tabs' className={classes['gallery-nav']}> 
          {tabs.map((tab, i) => <span key={i} id={`tab${i}`} className={this.state.tabIndex === i ? classes['tab-selected'] : classes['tab']} onClick={() => this.setState({tabIndex: i, page: this.state.page})}>{tab}</span>)}
        </div>
        <div style={{display: 'grid', gridTemplateColumns}}>
          {blogs.map((blog, i) => {
            if (i >= this.state.page * 6) { return null; }
            let relatedBlogs = blogs.map(blog => blog);
            relatedBlogs = getRelatedBlogs(blog, relatedBlogs);
            let img_url = `https://zora-blog.s3.us-east-2.amazonaws.com/${blog.id}.webp`
            return (<div key={i}>
              <Link to={`/blog/${blog.path}`} state={{blog, relatedBlogs}}>
                <div style={{paddingBottom: 50, width: '90%', marginRight: 'auto', marginLeft: isMobile && 'auto'}}>
                  <img alt={blog.alt_tag} src={img_url} style={{width: '100%', height: 200, objectFit: 'cover', marginBottom: 10}} />
                  <div className='roboto' style={{color: colors.gold, marginBottom: 10}}>{category === 'All Topics' ? (blog.categories.split(',')[0] || 'General') : category}</div>
                  <div style={{fontWeight: 'bold', fontSize: '1em'}}>{blog.title}</div>
                </div>
              </Link>
            </div>);
          })}
        </div>
        {(this.page + 1) * 6 < blogs.length && <div>Show More</div>}
      </div>
    )
  }
}

const BlogGalleryComponent = (props) => renderClass(BlogGallery, {styles: BlogGalleryStyles, props});


export class BlogPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      blogs: []
    };
    this.loadBlogs = loadBlogs.bind(this);
  }

  async componentDidMount() {
    await this.loadBlogs();
  }
  
  render() {
    return (
      <>
        <ScrollToTopOnMount />
        <BlogTitle blogs={this.state.blogs}/>
        <BlogGalleryComponent blogs={this.state.blogs}/>
      </>
    );
  }
}

