import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import HomePage from './HomePage';
import { BlogPage } from './BlogPage';
import DefaultPage from './DefaultPage';
import SubscriptionsPage from './SubscriptionsPage';
import BlogArticle from './BlogArticle';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';
import pageContent from './pageContent';
import { SubscriptionForm, ConsultationForm } from './ConsultationForm';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

let target = window.location.hash.replace('#', '');
window.location.hash = '';

window.onload = (e) => {
  if (target){
    let scrollTo = document.getElementById(target.slice(0, -1));
    if (scrollTo)
      window.scroll({top: scrollTo.offsetTop, behavior: 'smooth'})
  }
}

function testWebPSupport() {
  return new Promise( (resolve) => {
    const webp = "data:image/webp;base64,UklGRkAAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAIAAAAAAFZQOCAYAAAAMAEAnQEqAQABAAFAJiWkAANwAP79NmgA";
    const test_img = new Image();
    test_img.src = webp;
    test_img.onerror = e => resolve( false );
    test_img.onload = e => resolve( true );
  } );
}

(async ()=> {
  const supports_webp = await testWebPSupport();
  if( !supports_webp ) {
    document.getElementById('gallery-container')?.classList.add( 'no-webp' );
    document.getElementById('page-title')?.classList.add( 'no-webp' );
    document.getElementById('contact')?.classList.add( 'no-webp' );
  } else {
    document.getElementById('gallery-container')?.classList.add('webp');
    document.getElementById('page-title')?.classList.add( 'no-webp' );
    document.getElementById('contact')?.classList.add('webp');
  }
})();

function addChat(ng,a,g,e,l,i,ve){
  l = a.createElement(g);
  l.async=1;l.src=ng+e;
  var c=a.getElementsByTagName(g)[0];
  c.parentNode.insertBefore(l,c);
  var i=a.createElement('div');
  var ve='style';
  i.id='nGageLH';i[ve].position='fixed';i[ve].right='0px';i[ve].bottom='0px';i[ve].zIndex='5000';a.body&&a.body.appendChild(i);
}
addChat('https://messenger.ngageics.com/ilnksrvr.aspx?websiteid=',document,'script','25-43-39-253-29-75-166-66');



ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <title>Watson Law, LLC – Marietta Business Attorney</title>
      <meta name='description' content='A business lawyer, attorney and law firm based in Marietta, Georgia. We help business owners avoid legal problems.' data-react-helmet="true"/>
    </Helmet>
    <BrowserRouter>
      <div id='App' className="App" style={{overflow: 'hidden'}}>
        <SubscriptionForm />
        <ConsultationForm />
        <Header/>
        <Routes>
          <Route index element={<HomePage location='home'/>} />
          <Route path='/blog' element={<BlogPage />} />
          <Route path='/mergers-and-acquisitions' element={<DefaultPage {...{...pageContent.mergers, location: 'mergers'}} />} />
          <Route path='/business-law' element={<DefaultPage {...{...pageContent.businessLaw, location: 'business-law'}} />} />
          <Route path='/business-formation' element={<DefaultPage {...{...pageContent.businessFormation, location: 'business-formation'}} />} />
          <Route path='/contracts' element={<DefaultPage {...{...pageContent.contracts, location: 'contracts'}} />} />
          <Route path='/non-profit' element={<DefaultPage {...{...pageContent.nonProfit, location: 'non-profit'}} />} />
          <Route path='/partnerships' element={<DefaultPage {...{...pageContent.partnerships, location: 'partnerships'}} />} />
          <Route path='/subscriptions' element={<SubscriptionsPage />} />
          <Route path='/blog/:id' element={<BlogArticle/>} />
          <Route path="*" element={<Navigate replace to='/' />} status='404' />
        </Routes>
        <Contact />
        <Footer />
      </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

