import { ResponsiveImage } from './breakpoints.js';
import { FormButton } from './ConsultationForm';



function LandingImage(props) {

  return (
    <div className='landing-image-container'>
      <div className='spacing'>
        <div className='roboto'>
          <h2 className='lora'>We Help Business Owners Avoid Legal Problems</h2>
          <p>Marietta Georgia Business Law Attorney</p>
          <p>Protect your company and save money with legal expertise you can trust</p>
          <FormButton type='consultation' className='button-blue' text='REQUEST A CONSULTATION'/>
        </div>
        <div className='lightbulb'>
        <ResponsiveImage
          mobile=''
          tablet='/lightbulb_large.webp'
          default='/lightbulb_large.webp'
          large='/lightbulb_large.webp'
          backup='/lightbulb_large.png'
          />
        </div>
      </div>
      <ResponsiveImage className='landing-image asyncImage'
          mobile='/landing_img_xs.webp, /landing_img_xs.png'
          tablet='/landing_img_small.webp, /landing_img_small.png'
          default='/landing_img_med.webp, /landing_img_med.png'
          large='/landing_img_large.webp, /landing_img_large.png'
          backup='/landing_img_large.png'
          />
    </div>
  );
}

export default LandingImage;