import { IsMobile } from './breakpoints.js';


function PageTitle(props) {
  let isMobile = IsMobile();

  return (
    <div id='page-title'>
      <h1>{props.title}</h1>  
      <div className={`tag-${(isMobile && props.number !== 'P') ? 'col' : 'row'}`}>
        <strong style={{marginBottom: props.number === 'P' ? 'auto' : null, paddingRight: props.number === 'P' ? '0.25em' : '0.5em'}}>{props.number}</strong>
        <p style={{paddingTop: props.number === 'P' && '0px'}}>{props.colorText}</p>
      </div>
      {props.list && 
        <div className='list-container'>
          <ul>
            {props.list[0].map(li => <li>{li}</li>)}
          </ul>
          <ul>
            {props.list[1].map(li => <li>{li}</li>)}
          </ul>
        </div>
      }
      <hr/>
      <p className='subtext'>
        {props.subText}
      </p>

    </div>  
      
  );
}

export default PageTitle;