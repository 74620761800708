let mergers = {
  title: {
    title: 'Mergers & Acquisitions Attorney',
    number: '500,000+',
    colorText: 'More than half a million businesses sell each year, and this number is expected to skyrocket in the coming years.',
    subText: "When your company needs to buy or sell, you need the right legal help. Our mergers and acquisitions services can help with all sorts of transactions, such as company sales and purchases or consolidations and mergers. We'll provide informed advice and represent your company during the process to help you get the deal completed."
  },
  subheader: {
    header: 'Business Purchases and Sales',
    text: 'Starting your own business can be risky. However, when you buy an existing company, you can be an entrepreneur or expand your current business without having to start from scratch.'
  },
  illustration: {
    image: 'mergers_image',
    header: 'Closing the Deal',
    text: "It’s time to make a deal! We can help you with the paperwork after we’ve agreed on the price and the terms. Poor acquisitions can lead to owners’ equity being lost – either by selecting a bad target, or by executing poorly after the acquisition."
  },
  whatWeDo: [[
      "Assist our clients with the appropriate financing",
      "Advise on deal and negotiating tactics",
      "Conduct due diligence",
      "Provide advice concerning the drafting and performance of contracts for the sale of portions of the business",
      "Identify the legal issues",
    ],
    [
      "Determine (with the help of tax attorneys) the tax implications and if they require special structuring",
      "Work with antitrust attorneys to assess regulatory obstacles, gain regulatory approval and analyze any other required regulatory approvals",
      "Obtain third-party consents from lenders or parties to other contracts."
    ]
  ]
}

let businessLaw = {
  title: {
    title: 'General Business Lawyer',
    number: '5',
    colorText: 'of the most common types of Business Litigation cases are Intellectual property disputes, breach of contract cases, employment disputes, partnership disputes and product liability cases or personal injury claims.',
    subText: "As experts in business law, we take care of all your needs, so you don't have to face legal problems alone. We help you with disputes and legal concerns related to contracts, the workplace, employees, real estate, and property. Our priority is to ensure compliance and manage risk for your company."
  },
  subheader: {
    header: 'Personalized Business Law Services',
    text: "As a business owner, you don't have the time to deal with legal issues. So it's smart to hire someone who does. Legal advice for small businesses is crucial. Legal problems can take up a lot of time and money. And litigation can be costly too, in both time and money. We provide in-depth knowledge of state and federal laws and regulations and fierce advocacy to businesses that are just getting started, looking to expand, or have been established for a while."
  },
  illustration: {
    image: 'business_law_image',
    header: 'Your Dedicated Partner',
    text: <>
      <strong>Watson Law is committed to supporting you and your company.</strong>
      <div style={{marginTop: '1em'}}>
        From when you first need to create an agreement or draft a document, all the way through protecting you from litigation, we are there to represent your company, guide you, and support you.
        It’s time to make a deal! We can help you with the paperwork after we’ve agreed on the price and the terms. Poor acquisitions can lead to owners’ equity being lost – either by selecting a bad target, or by executing poorly after the acquisition.
      </div>
    </>
  },
  whatWeDo: [[
      "General Counsel",
      "Settle Business Disputes",
      "Contracts"
    ],
    [
      "Partnership & Shareholders",
      "Non-Profit Law",
    ]
  ]
}

let businessFormation = {
  title: {
    title: 'Business Formation Attorney',
    number: '4.5 M',
    colorText: 'new business registrations were submitted and data shows an increasing number of people in the United States have started their own businesses over the past decade.',
    subText: "Forming a business can be confusing and time-consuming. We can help you with any organization, from a C corporation to an LLC. Our lawyers provide consultation so that your choice of legal structure is for your short-term and long-term goals. No matter which entity you choose, we will ensure you have the correct paperwork and documents to avoid mistakes and protect your best interests."
  },
  subheader: {
    header: 'Registering A New Business',
    text: "If you're starting a new business, it's important to know the legal obligations of running a small business. The best first step is to consult a lawyer, who can help you understand what you need to do."
  },
  illustration: {
    image: 'business_formation_image',
    header: 'Legal Requirements for Starting a Business',
    text: "The business structure you choose for your company is an incredibly important decision. It will dictate how your company operates in terms of taxes, liability, and reporting requirements.  Regardless of what type of organization you are forming, there are pros and cons to take into account. Here at Watson Law LLC, we can help you choose the right structure for your business. "
  },
  whatWeDo: [[
      "Walk you through the business formation process",
      "Creating organizing documents",
      "Draft and file the needed documents",
      "Support with trademarking your name"
    ],
  ]
}

let contracts = {
  title: {
    title: 'Contracts',
    number: '10',
    colorText: 'Essential Contracts For Small And Growing Businesses include:',
    subText: "We specialize in various contracts, including employment agreements, leasing agreements, franchise agreements, non-compete agreements, and the like. All contracts are tailored to your needs. We'll prepare and review contracts, so you have all the bases covered.",
    list: [
      [
        'Employment Offer Letters',
        'Confidentiality and Invention Assignment Agreements',
        'Service Contracts',
        'Confidentiality Agreements',
        'Website Terms of Use Agreement'
      ],
      [
        'Sales Contracts',
        'Letter of Intent',
        'Stock Purchase Agreements',
        'Leases',
        'Loan Agreements'
      ]
    ]
  },
  subheader: {
    header: 'Contract Law Services',
    text: "We know contracts can be complicated. But we're here to help! With our contract drafting services, we make sure you know what you're signing and have the best advice for your contract-related needs. We help our clients with drafting, reviewing, and litigating any contract-related issue. No matter what you need assistance with, we're here for you. "
  },
  illustration: {
    image: 'contracts_image',
    header: 'Contract-Related Consultation',
    text: "We help you stay protected and run your business smoothly. We work with new startups, established companies, and shareholders on a daily basis. Whatever your contract-related issue, call Watson today."
  },
  whatWeDo: [[
      "Buy/Sell Agreements",
      "Employment Contracts",
      "Mergers and Acquisitions",
      "Non-compete, Non-disclosure and Confidentiality agreements"
    ],
    [
      "Commercial Leasing Agreements",
      "Franchising Contracts",
      "Arbitration Clauses",
      "Service Agreements"
    ]
  ]
}

let nonProfit = {
  title: {
    title: 'Non-Profit Lawyer',
    number: '29',
    colorText: 'is the number of different types of 501(c) organizations that the IRS recognizes',
    subText: "Our law firm has helped clients start and grow these organizations that do good in the world. No matter what you need, we can assist with paperwork, legal forms, and everything else you might need to start a 501(c) organization. We're here to help."
  },
  subheader: {
    header: 'Get Guidance',
    text: "When starting a nonprofit, it can be helpful to consult with an attorney. Start with a one-on-one consultation with a non profit attorney. During the session, we'll answer your questions, give you advice customized to your nonprofit, and help you through the process."
  },
  illustration: {
    image: 'non_profit_image',
    header: 'Become a 501(c)',
    text: <>
      <div style={{marginBottom: '1.5em'}}>
        Before you can get 501(c) status, it's important to set up the legal foundation of your non-profit. With our help, you can protect yourself and your board of directors from any legal issues. Nonprofits should also include clauses that grants require before they will fund your nonprofit.  
      </div>
      <div>
        Watson nonprofit attorneys can help you with your federal tax identification number (EIN). They provide unlimited legal representation throughout the application process and respond to any IRS requests for more information about your application.
      </div>
    </>
  },
  whatWeDo: [[
      "Non-profit law and guidance",
      "Help applying for 501(c)",
      "Legal representation with IRS",
    ],
  ]
}

let partnerships = {
  title: {
    title: 'Partnership Litigation Lawyer',
    number: 'P',
    colorText: "artnerships are very important, but they can lead to disputes. In order to avoid disagreements from the onset, it's important to start off with the right legal advice.",
    subText: "We help our clients resolve disputes between shareholders, business partners, LLC members, and joint ventures. Our attorneys counsel and represent you in partnership matters. We help our clients with preparing legal documents for partnerships. We also help advocate, negotiate, mediate and resolve disputes with business partners. We also help with litigation. "
  },
  subheader: {
    header: 'Partnership Agreements',
    text: "We can take care of paperwork for you. We can draft agreements, such as partnership agreements, operating agreements, employment agreements, and non-disclosure agreements. These agreements will detail how you and your partners will run your business and how to avoid disputes before they arise."
  },
  illustration: {
    image: 'partnership_image',
    header: 'Litigation & Dispute Resolution',
    text: <>
      <div style={{marginBottom: '1.5em'}}>
        Sometimes, partnerships go awry. They may be breaking the law or violating contract agreements. It's also possible that one partner is using company assets or there is an issue with fiduciary duty. 
      </div>
      <div>
        Whether you need to dissolve the partnership or get repayment for problems caused by their bad behavior, Watson Law Firm can help.
      </div>
    </>
  },
  whatWeDo: [[
      "Partnership Agreements",
      "Operating Agreements",
      "Non-Compete Agreements",
    ],
    [
      "Non-Disclosure Agreements",
      "Litigation & Dispute Resolution "
    ]
  ]
}

const content = { mergers, businessLaw, businessFormation, contracts, nonProfit, partnerships };
export default content;