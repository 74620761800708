import { IsLarge, IsTablet, IsMobile } from './breakpoints.js';
import colors from './colors';
import checkmark from './img/checkmark.png';
import { Link } from 'react-router-dom';
import { FormButton } from './ConsultationForm';

let plans = [
  {
    background: '#FBD895',
    title: 'ENTREPRENEUR',
    bullets: [
      '10 hours of general legal counsel – scheduled phone calls and emails',
      'Limited monthly document review (up to a total of 50 pages)',
      'Annual business assessment conference',
      '10% reduction in legal fees not covered by the plan',
      'Registered Agent Services'
    ],
    price: 500
  },
  {
    background: 'white',
    title: 'BOSS',
    bullets: [
      'Everything in Entrepreneur subscription plan',
      'Drafting of documents up to 30 pages',
      'Additional 50 pages of monthly document review (a total of 100 pages)',
      'Quarterly business assessment conference',
      '15% reduction in legal fees not covered by the plan',
    ],
    price: 750,
  },
  {
    background: '#FBD895',
    title: 'BUSINESS TYCOON',
    bullets: [
      'Everything in Entrepreneur and Boss subscription plans',
      'One on-site visit (provided that the location is within 2 hours of Marietta, Georgia)',
      'Additional 50 pages of monthly document review (a total of 150 pages)',
      'Additional 30 pages drafting of business documents (a total of 60 pages)',
      '20% reduction in legal fees not covered by the plan'
    ],
    price: 1250
  }
]

function Subscription(props) {
  let isLarge = IsLarge();
  let isTablet = IsTablet();
  let isMobile = IsMobile();

  let height = isTablet || isMobile ? null : isLarge ? 700 : 750;
  let paddingBottom = isTablet || isMobile ? 200 : null;
  let marginBottom = isMobile ? 30 : isTablet ? 40 : null;
  let width = isMobile ? '80%' : isTablet ? '70%' : '30%'
  let buttonWidth = isLarge ? 150 : 125;
  let vPadding = 25;
  return (
    <div style={{position: 'relative', textAlign: 'center', padding: vPadding + 'px 0px', background: props.background, boxShadow: '0px 4px 16px -12px #01033C', width, lineHeight: '150%', height, paddingBottom, marginBottom}}>
      <div style={{fontWeight: 700, marginBottom: vPadding + 'px'}}>{props.title}</div>
      {props.bullets.map((bullet, i) => (
        <div key={i} className='flex-row roboto' style={{marginTop: '1.5em', width: '80%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'left'}}>
          <img src={checkmark} alt='checkmark' style={{height: '1.5em', paddingRight: '1.5em'}} />
          <span>{bullet}</span>
        </div>
      ))}
      <div style={{position: 'absolute', bottom: 75, width: '100%'}}>
        <FormButton type='subscription' subscriptionType={props.title} style={{marginLeft: 'auto', marginRight: 'auto', width: buttonWidth}} text='ENROLL' />
        <div style={{fontWeight: 700, marginTop: '1em'}}>${props.price}/Month</div>
      </div>
    </div>
  )
}


export const SubscriptionsWidget = (props) => {
  const isLarge = IsLarge();
  let fontSize = isLarge ? 18 : 16;
  return (
    <>
      <div style={{background: 'rgba(249, 249, 249, 0.78)', padding: '30px 0px', fontSize, overflowX: 'hidden'}}>
        <div style={{textAlign: 'center', color: colors.gold, fontSize: '1.9em', paddingBottom: '30px'}}>Business Law Subscriptions</div>
        <div className={`flex-row aic jcsa roboto`} style={{maxWidth: 1440, margin: 'auto' }}>      
          {plans.map((plan, i) => <Subscription key={i} {...plan} />)}
        </div>
       <div style={{textAlign: 'center', fontWeight: 700, marginTop: '1em'}}> <Link to='/subscriptions'>Learn More</Link></div>
      </div> 
    </>
  )
 }


 export const  SubscriptionsMain = (props) => {
  let isLarge = IsLarge();
  let isTablet = IsTablet();
  let isMobile = IsMobile();

  let fontSize = isMobile ? 14 : isTablet || isLarge ? 18 : 16;
  let flexClass = isTablet || isMobile ? 'flex-col' : 'flex-row'
  let marginTop = isTablet || isMobile ? -500 : -200;
  return (
    <>
      <div style={{position: 'relative', background: 'rgba(249, 249, 249, 0.78)', padding: '30px 0px', fontSize,  overflowY: 'visible', zIndex: 5}}>
        <div className={`${flexClass} aic jcsa roboto`} style={{position: 'relative', maxWidth: 1440, margin: 'auto', marginTop}}>      
          {plans.map((plan, i) => <Subscription key={i} {...plan} />)}
        </div>
       <div style={{color: '#555555', textAlign: 'center', marginTop: '1em'}}>All plans require a minimum of a six month commitment</div>
      </div> 
    </>
  )
 }

const Components = { SubscriptionsWidget, SubscriptionsMain }; 

export default Components;