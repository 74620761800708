import React from 'react';
import arrow_left from './img/arrow_left.png';
import arrow_right from './img/arrow_right.png';

let content = [
  "Watson Law is a business law firm focused on helping clients save money and avoid legal problems. Our practice is focused on providing solid legal advice and assisting our clients in avoiding problems that might grow into more expensive legal issues.",
  "Our goal is to minimize the cost associated with legal services so you can focus on running your business. At Watson Law we provide proactive guidance, education, and legal support to our clients so they can spend their time focusing on growing their businesses rather than worrying about legal issues.",
]

class Gallery extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      index: 0
    }
    this.scroll = this.scroll.bind(this);
  }

  scroll(i) {
    this.setState({index: i});
    document.getElementById('gallery').scrollLeft = i * document.getElementById('gallery').offsetWidth;

  }
  
  handleScroll(e) {
    console.log(e.target.offsetWidth);
    console.log(e.target.scrollLeft);
    if(this.state.index === 0 && e.target.scrollLeft >= (e.target.width / 2)){
      this.setIndex(1);
    }
    else if (this.state.index === 1 && e.target.scrollLeft < (window.screen.width / 2)){
      this.setIndex(0);
    }
    if (this.handle)
        clearTimeout(this.handle);
      this.handle = setTimeout(() => {
        if (this.state.index === 0)
          this.scroll(0);
        else
          this.scroll(1);
      }, 100); 
  }


  render() {
    return (
      <div id='gallery-container' className='roboto'>
        <div className='flex-row aic jcc'>
          <div className='sidebar'>
            <div className='arrow-button' onClick={() => this.scroll((this.state.index + 1) % 2)} ><img src={arrow_left} alt='scroll left' /></div>
          </div>
          <div id='gallery' className='scrollable'>
            <div>
              {content.map((c, i) => (
                <div className='page' key={i}>
                  <span>
                    {c}
                  </span>
                </div> 
              ))}
            </div>
          </div>
          <div className='sidebar'>
            <div className='arrow-button' onClick={() => this.scroll((this.state.index + 1) % 2)} ><img src={arrow_right} alt='scroll right' /></div>
          </div>
        </div>
        <div className='mobile-nav'>
          {this.state.index !== (content.length - 1) && <div className='arrow-button' onClick={() => this.scroll(this.state.index + 1)} ><img src={arrow_right} alt='scroll right'/></div>}
          {this.state.index !== 0 && <div className='arrow-button' onClick={() => this.scroll(this.state.index - 1)} ><img src={arrow_left} alt='scroll left'/></div>}
        </div>
      </div>
    )
  }
}

export default Gallery;