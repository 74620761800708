import location_icon from './img/location_icon.png'
import mobile_icon from './img/mobile_icon.png';
import message_icon from './img/message_icon.png';
import linkdin_icon from './img/linkdin.png';
import facebook_icon from './img/facebook.png';
import { ResponsiveImage } from './breakpoints.js';
import { Link } from 'react-router-dom';

function Footer(props) {
  return (
    <>
    <div id='footer'>
      <div className='col1'>
        <ResponsiveImage alt='Watson Law LLC Logo'
            mobile='/logo_white.webp'
            tablet='/logo_white.webp'
            default='/logo_white.webp'
            large='/logo_white.webp'
            backup='/logo_white.png'
        />
        <p>Your Small Business and Criminal Law Firm.</p>
        <div className='social'>
          <a target="_blank" rel='noreferrer' href='https://www.linkedin.com/in/a-zakiya-watson-caffe-a597b036/'><img src={linkdin_icon} alt='LinkedIn logo'/></a>
          <a target="_blank" rel='noreferrer' href='https://www.facebook.com/watsonlawllc/'><img src={facebook_icon}  alt='Facebook logo'/></a>
        </div>
      </div>
      <div className='col2'>
        <h3>Law Services</h3>
        <Link to=''>Home</Link>
        <Link to='business-law'>Business Law</Link>
        <Link to='business-formation'>Business Organizations</Link>
        <Link to='mergers-and-acquisitions'>Mergers and Acquisitions</Link>
        <Link to='contracts'>Contracts</Link>
      </div>
      <div className='col2'>
        <h3>&nbsp;</h3>
        <Link to='partnerships'>Partnerships</Link>
        <Link to='non-profit'>Non-profit Lawyer</Link>
        <Link to='subscriptions'>Business Law Subscriptions</Link>
        <Link to='blog'>Blog</Link>
      </div>
      <div className='contact'>
        <h3 className='desktop'>Reach Us</h3>
        <a href="mailto: zak@zwatsonlaw.com"><img src={message_icon} alt='Email icon'/>zak@zwatsonlaw.com</a>
        <a href="tel:404-600-1771" ><img src={mobile_icon} alt='Phone icon'/>404-600-1771</a>
        <a href="https://goo.gl/maps/HYvRoPRXxk4UB93q7" style={{alignItems: 'flex-start'}}>
          <img src={location_icon} alt='Address icon' style={{height: '1.4em'}}/>
          <div>
            <p>
              Watson Law LLC <br/>
              800 Kennesaw Avenue, Suite 220 <br/>
              Marietta, Georgia 30060
            </p>
          </div>
        </a>
      </div>
    </div>
    <div id='copyright'> Watson Law LLC © 2021 </div>
    </>
  )
 }

export default Footer;