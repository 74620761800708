import React from 'react';
import LandingImage from './LandingImage';
import Overlay from './Overlay';
import Gallery from './Gallery';
import Bio from './Bio';
import PracticeAreas from './PracticeAreas';
import Reviews from './Reviews';

function ScrollToTopOnMount() {
  React.useEffect(() => {
    if(!window.location.hash)
      window.scrollTo(0, 0);
  }, []);

  return null;
}


class HomePage extends React.Component {

  componentDidMount() {
    const objects = document.getElementsByClassName('asyncImage');
    Array.from(objects).map((item) => {
      // Start loading image
      const img = new Image();
      img.src = item.dataset.src;
      // Once image is loaded replace the src of the HTML element
      img.onload = () => {
        item.classList.remove('asyncImage');
        return item.nodeName === 'IMG' ? 
          item.src = item.dataset.src :        
          item.style.backgroundImage = `url(${item.dataset.src})`;
      }
      return null;
    });
  }

  render() {
    return (
      <>
        <ScrollToTopOnMount />
        <LandingImage />
        <Gallery />
        <Overlay />
        <Bio />
        <PracticeAreas />
        <Reviews />
      </>
    );
  }
}

export default HomePage;
