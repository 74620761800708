import { renderClass  } from './breakpoints.js';
import React from 'react';
import { useLocation, Link, Navigate, useParams} from 'react-router-dom';
import BlogWidget from './BlogWidget'
import { loadBlogs } from './BlogPage';
import { Helmet } from 'react-helmet';
function ScrollToTopOnMount() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

class BlogArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      blogs: this.props.relatedBlogs
    };

    this.loadBlogs = loadBlogs.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  async componentDidMount() {
    if (this.props.relatedBlogs){
      this.setState({
        isLoaded: true,
        blogs: this.props.relatedBlogs
      });
      return;
    }
    await this.loadBlogs(); //TODO: Here, still need to filter related blogs...
    //TODO: Need to render blog from localStorage and NOT props...
  }

  render() {
    if (!this.props.blog && !this.state.isLoaded)
      return null;
    let blog = this.props.blog || this.state.blogs.filter(blog => (blog.path.toLowerCase() === this.props.id.toLowerCase()))[0];
    if (!blog)
      return <Navigate replace to='/blog' />
    let isTablet = this.props.isTablet;
    let isMobile = this.props.isMobile;
    let titleLeft = isTablet || isMobile ? '10%' : 100;
    let titleMarginRight = titleLeft;
    let titleTop = isTablet || isMobile ? '30%' : '45%';
    let titleFontSize = isMobile ? 30 : null;
    return (<>
      <ScrollToTopOnMount />
      <Helmet>
        <title>{blog?.title}</title>
        <meta name='description' content={blog?.meta_content} />
      </Helmet>
      <div id='blog-title' className='lora' style={{color: 'white', fontSize: '2.5em', position: 'relative', background: `rgba(9, 12, 67, 100)`, width: '100%', height: 500}}> 
        <img alt={blog?.alt_tag || 'Blog Thumbnail Image'}  src={`https://zora-blog.s3.us-east-2.amazonaws.com/${blog.id}.webp`} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', opacity: .6}} />
        <div style={{ position: 'relative', width: '100%', height: '100%', maxWidth: 1440, margin: 'auto'}}>
          <div style={{position: 'absolute', fontSize: titleFontSize, top: titleTop, left: titleLeft, marginRight: titleMarginRight}}>{blog.title}</div>
          <Link to='/blog'>← Back to Blog</Link>
        </div>
      </div>
      <div id='blog-article' dangerouslySetInnerHTML={{ __html: blog.content }} style={{width: '80%', maxWidth: 1440, margin: 'auto', padding: '50px 0px', fontSize: 16}}/>
      {(this.state.isLoaded && this.state.blogs.length > 0) &&  <BlogWidget title='Related Articles' background='rgba(249, 249, 249, 0.78)' exclude={blog.id}/>
      }
    </>);
  }
}

const BlogArticleComponent = (props) => { 
  let location = useLocation(); 
  let { id } = useParams();
  let blog = location.state?.blog; 
  let relatedBlogs = location.state?.relatedBlogs;
  props = {...props, id, blog, relatedBlogs, location: location.pathname}; 
  return renderClass(BlogArticle, {props}) 
}

export default BlogArticleComponent;