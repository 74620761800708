import React from 'react';
import SubscriptionsTitle from './SubscriptionsTitle';
import { SubscriptionsMain } from './SubscriptionsWidget';
import SubscriptionServices from './SubscriptionServices';
import { IsTablet, IsMobile, IsLarge } from './breakpoints';

function ScrollToTopOnMount() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function BusinessLaywer() {
  let isTablet = IsTablet();
  let isLarge = IsLarge();
  let isMobile = IsMobile();

  let fontSize = isMobile ? 12 : isTablet ? 16 : isLarge ? 20 : 18;
  let vPadding = isLarge ? '75px' : '40px';
  let hPadding = isTablet || isMobile ? '5%' : isLarge ? '100px' : '75px';
  let maxWidth = 1440;

 
  return (
    <div style={{fontSize, maxWidth, margin: 'auto', padding: `${vPadding} ${hPadding}`}}>
      <div style={{textAlign: 'center', color: '#E19E39', marginBottom: '1em', fontSize: '1.75em', }}>Business Lawyer</div>
      <div className='roboto' style={{width: '80%', maxWidth: 1440, margin: 'auto', lineHeight: '175%'}}>
        <p style={{marginBottom: '1.5em'}}>
          Our firm works on business matters ranging from basic incorporation to complex commercial deals. We are especially passionate about helping small businesses with their legal needs. It is rewarding to help a start-up business flourish into a formidable force in its community.  
        </p>
        <p style={{marginBottom: '1.5em'}}>
          In advising businesses on their legal matters, we understand that every dollar a business spends on legal fees needs to have a positive investment return. That is why our business legal services are practical and effective. We start by approaching our business law services from the perspective of a business owner.
        </p>
        <p style={{marginBottom: '1.5em'}}>
          Our success is defined by our client’s success. Not only do we work with clients to solve problems, our overall objective is to prevent problems from happening. We believe this approach in the end will yield the best ROI for your professional fees. We understand that our legal services must align with the objectives, enhancing growth and profitability of our small business clients.
        </p>
        <p style={{marginBottom: '1.5em'}}>
          Whether you are just starting your business or organization, or have been in business for several decades, our business litigation lawyer in Marietta can assist you!
        </p>
  
      </div>
    </div>  
      
  );
}

class SubscriptionsPage extends React.Component {
  render() {
    return (
      <>
        <ScrollToTopOnMount />
        <SubscriptionsTitle />
        <SubscriptionsMain />
        <BusinessLaywer />
        <SubscriptionServices />
      </>
    );
  }
}

export default SubscriptionsPage;
