import { useMediaQuery } from 'react-responsive';


export const mobileWidth = 499;
export const tabletWidth = 900;
export const largeWidth = 1439;

export const Mobile = ({ children }) => {
  return IsMobile() ? children : null
}

export const TabletOrMobile = ({ children }) => {
  const isNotMobile = useMediaQuery({ maxWidth: tabletWidth })
  return isNotMobile ? children : null
}

export const SmallTabletOrMobile = ({ children }) => {
  const isNotMobile = useMediaQuery({ maxWidth: 719 })
  return isNotMobile ? children : null
}

export const BigTabletOrDefault = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 720 })
  return isNotMobile ? children : null
}

export const TabletOrDefault = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 500 })
  return isNotMobile ? children : null
}

export const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: tabletWidth + 1 })
  return isNotMobile ? children : null
}

export const Tablet = ({ children }) => {
  return IsTablet() ? children : null
}

export const Large = ({ children }) => {
  return IsLarge() ? children : null
}

export const IsMobile = () => useMediaQuery(
  { maxWidth: mobileWidth },
);

export const IsTablet = () => useMediaQuery(
  { minWidth: mobileWidth + 1, maxWidth: tabletWidth },
);

export const IsLarge = () => useMediaQuery(
  { minWidth: largeWidth + 1 },
);

export const renderClass = (Component, {props, styles}) => {
  let isMobile = IsMobile(); 
  let isTablet = IsTablet();
  let isLarge = IsLarge();
  let classes = null;
  if (styles) {
    classes = styles();
  }
  return <Component isLarge={isLarge} isMobile={isMobile} isTablet={isTablet} classes={classes} {...props} />
}

export const ResponsiveImage = (props) => {
  return (
    <picture className={props.className} id={props.id}>
      <source srcset={props.mobile} media={`(max-width: ${mobileWidth}px)`} /> 
      <source srcset={props.tablet} media={`(max-width: ${tabletWidth}px)`}/> 
      <source srcset={props.default} media={`(max-width: ${largeWidth}px)`}/> 
      <source srcset={props.large} media={`(min-width: ${largeWidth + 1}px)`}/> 
      <img src={props.backup} alt={props.alt || ''} />
    </picture>
  )
}