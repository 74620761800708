import React from 'react';
import PageTitle from './PageTitle';
import PageSubheader from './PageSubheader';
import PageIllustration from './PageIllustration';
import WhatWeDo from './WhatWeDo';
import { SubscriptionsWidget } from './SubscriptionsWidget';
import BlogWidget from './BlogWidget';
import { Default } from './breakpoints';

function ScrollToTopOnMount() {
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}


class DefaultPage extends React.Component {
  
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    
    return (
      <>
        <ScrollToTopOnMount />
        <PageTitle {...this.props.title}/>
        <PageSubheader {...this.props.subheader}/>
        <PageIllustration {...this.props.illustration}/>
        <WhatWeDo columns={this.props.whatWeDo} />
        <Default>
          <SubscriptionsWidget />
        </Default>
        <BlogWidget />
      </>
    );
  }
}

export default DefaultPage;
