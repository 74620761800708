import React from 'react';
import homepage_icon1 from './img/homepage_icon1.png';
import homepage_icon2 from './img/homepage_icon2.png';
import homepage_icon3 from './img/homepage_icon3.png';

function Overlay(props){
  return (
    <> 
      {/* Tablet / Desktop */}
        <div className='homepage-overlay-container roboto'>
          <div>
            <div className='homepage-overlay'>
              <div>
                <div className='flex-row aic'>
                  <img src={homepage_icon1} alt=''/>
                  CLIENT FIRST
                </div> 
                <div>
                  We are committed to you, the client. We will work as hard as it takes to assist your business, meet your goals, and be there for you when you need us.
                </div>
              </div>
              <div>
                <div className='flex-row aic'>
                  <img src={homepage_icon2} alt='' />
                  EXPERTISE
                </div>
                <div>
                  With our degrees in business and law, we bring a unique perspective to legal services.
                </div>
              </div>
              <div>
                <div className='flex-row aic'>
                  <img src={homepage_icon3} alt='' />
                  HIGHEST QUALITY, AFFORDABLE PRICE
                </div>
                <div>
                  We offer the highest quality service at the most reasonable prices in metro Atlanta and North Georgia.
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* MOBILE */}
      <div className='mobile-homepage-overlay roboto'>
        <div className='icon-box' >
          <img src={homepage_icon1} alt='' />
        </div>
        <h5>
          CLIENT FIRST
        </h5> 
        <div>
          We are committed to you, the client. We will work as hard as it takes to assist your business, meet your goals, and be there for you when you need us.
        </div>
        <div className='icon-box'>
          <img src={homepage_icon2} alt='' />
        </div>
        <h5>
          EXPERTISE
        </h5>
        <div>
          With our degrees in business and law, we bring a unique perspective to legal services.
        </div>
        <div className='icon-box'>
          <img src={homepage_icon3} alt='' />
        </div>
        <h5>
          HIGHEST QUALITY, AFFORDABLE PRICE
        </h5>
        <div>
          We offer the highest quality service at the most reasonable prices in metro Atlanta and North Georgia.
        </div>
      </div>
    </>
  )
}

export default Overlay