import React from 'react';
import gold_star from './img/gold_star.png';

function Page(props) {
  return (
    <>
      <div id={'review' + props.ind} className='review'>
        <p className='content' style={{fontSize: props.review.length > 350 && '.75em'}}>
          {props.review}
        </p>
        <div className='stars'>
          <img src={gold_star}  alt=''/>&nbsp;
          <img src={gold_star}  alt=''/>&nbsp;
          <img src={gold_star}  alt=''/>&nbsp;
          <img src={gold_star}  alt=''/>&nbsp;
          <img src={gold_star}  alt=''/>
        </div>
        <p className='author'>{props.author}</p>
      </div>
    </>
  );
}

let content = [
  {
    review: '“She is the best attorney in metro Atlanta. She has  helped my family and I a great deal. If you are looking for someone who will put in the long hours and go that extre mile for her clients, she is definitely the one. 5 stars”',
    author: 'Saleemah C.'
  },
  {
    review: '“One of the best boutique law firms in the city! Attorney Watson-Caffe is an upstanding experienced attorney who provides dedicated legal representation in criminal defense, personal injury, and business matters. Fair fees and does not make you feel like you\'re just a number or a dollar sign. HIRE HER FOR ALL YOUR LEGAL Needs!!!”',
    author: 'Amirah W.'
  },
  {
    review: '“”This woman is nothing short of amazing! The very first conversation we she made it clear what our options where and what the possible outcome could be. She was the 2nd lawyer we hired to work on my husband case but really wish we would have started with her he may have never seen the inside of a jail. She honest and worth every penny. She stayed in touch every step of the way. My husband was jailed on his very 1st offense and was lead to believe he would be home in six months but Cobb county was doing everything they could to keep him there for a year. Mrs. Watson got his sentence modified and had him right away so thankful to her and the hard work she put in."',
    author: 'Deanna G.'
  },
  {
    review: '“Attorney Watson Caffe HANDS DOWN IS AND WILL FOREVER BE THE BEST ATTORNEY IN AMERICA!!! When I tell you she will fight strongly on your behalf, she won\'t stop until she wins your case and freedom. In legal situations she\'s person you want talk too. Attorney Watson Caffe will give you the truth; nothing in between; nothing that\'s going to make you think twice. I\'m so pleased with her services y\'all. This is no gimmick! SHES THE REAL DEAL..... IF I WAS YOU...YEAH YOU!!! HIRE HER TO BE YOUR ATTORNEY, YOU WONT REGRET IT. I promise you. 😀😀😀🤗🤗 Thank you so much Attorney Watson Caffe"',
    author: 'Devonere W.'
  },
  {
    review: '“Mrs. Watson assisted me with getting all the legal documents required for starting a Youth Athletic Program. Her dedication and commitment to our vision allowed us to focus on other aspects of launching the program, without worrying about the legal aspects of it. NYC Infantry Basketball Inc. is grateful for her services.”',
    author: 'NYC Infantry Basketball Inc.'
  },

]

class Reviews extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      index: 0
    }
    this.setIndex = this.setIndex.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handle = null;
  }

  componentDidMount() {
    this.scroll(this.state.index);
  }

  setIndex(i) {
    this.setState({index: i});
  }

  scroll(i) {
    let review = document.getElementById('review' + i);
    let reviewWidth = review.offsetWidth;
    let container = document.getElementById('reviews')    
    container.scroll({left: review.offsetLeft + (reviewWidth / 2) - (document.body.clientWidth / 2) , behavior: 'smooth'})
  }
  
  handleScroll(e) {
    let reviewWidth = document.getElementById('review0').offsetWidth;
    let center = e.target.scrollLeft + (document.body.clientWidth / 2)
    let bestInd = 0;
    let bestDist = 999999;
    for (let i = 0; i < content.length; i++){
      let reviewOffset = document.getElementById('review' + i).offsetLeft + (reviewWidth / 2);
      if (Math.abs(reviewOffset - center) < bestDist){
        bestDist = Math.abs(reviewOffset - center);
        bestInd = i;
      }
    }
    if (this.state.index !== bestInd){
      this.setIndex(bestInd);
    }
    if (this.handle)
        clearTimeout(this.handle);
      this.handle = setTimeout(() => {
        this.scroll(this.state.index);
      }, 100); 
    
  }

  render() {
    return (
      <div id='reviews-container'>
        <h2>What Customers Say About Us
        </h2>
        <div id='reviews' onScroll={this.handleScroll} className='scrollable'>
          <div className='gallery'>
            {content.map((c, i) => <Page key={i} ind={i} review={c.review} author={c.author}/>)}
          </div>
        </div>
        <div className='bottom-nav' >
          {content.map((_, i) => <div key={i} onClick={() => this.scroll(i)} className={this.state.index === i ? 'selected' : 'not-selected'} />)}
        </div>
      </div>
    )
  }
}

export default Reviews