import React from 'react';
import scale_icon from './img/scales.png';
import { renderClass } from './breakpoints';
import close from './img/close.png';

const subscriptionEndpoint = 'https://dljb9b3hmh.execute-api.us-east-2.amazonaws.com/watson-subscription'
const consultationEndpoint = 'https://dljb9b3hmh.execute-api.us-east-2.amazonaws.com/watson-consultation'

export function Button(props) { 
  return (
    <div className={`roboto button-gold ${props.className}`} onClick={props.onClick} style={{ ...props.style, }}>
        {props.text}
    </div>  
  );
}

export class FormButton extends React.Component {
  constructor(props) {
    super(props);
    this.showForm = this.showForm.bind(this);
  }

  showForm(e) {
    //open form
    if (this.props.type === 'subscription') {
      document.getElementById('subscription-form-container').style.display = 'flex';
      document.getElementById('subscription-type').innerText = this.props.subscriptionType;
    } else {
      document.getElementById('consultation-form-container').style.display = 'flex';
    }
    document.querySelector('body').style.overflow =  'hidden';

    //GTM trigger
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'event': 'formOpened',
      'formType': this.props.type
    });
  }

  render() {
    return <Button className={this.props.className} style={this.props.style} text={this.props.text} onClick={this.showForm} />
  }
}


class DefaultForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isOpen: false,
      name: '',
      email: '',
      phone: '',
      about: '',
      submitted: false
    }
    this.submitForm = this.submitForm.bind(this);
    this.checkCloseForm = this.checkCloseForm.bind(this);
    this.setName = this.setName.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setPhone = this.setPhone.bind(this);
    this.setAbout = this.setAbout.bind(this);
    this.scrollTop = 0;
  }

  componentWillUnmount() {
    document.querySelector('body').style.overflow =  'scroll';
  }

  async submitForm(e) {
    e.preventDefault();
    let state = this.state;
    const requestOptions = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
      },
      body: JSON.stringify({ 
        name: state.name,
        phone: state.phone,
        email: state.email,
        about: state.about,
        type: document.getElementById('subscription-type').innerText
      })
    };

    let endpoint = subscriptionEndpoint;
    if (this.props.type === 'consultation')
      endpoint = consultationEndpoint;

    let res = await fetch(endpoint, requestOptions);
    if (res.status !== 200)
      state.error = await res.text();
    else {
      state.submitted = true;
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'formSubmission',
        'formType': this.props.type
       });
    }
    this.setState(state);
  }

  checkCloseForm(e) {
    if (e.target.id === `${this.props.type}-form-container` 
     || e.target.id === `${this.props.type}-form-inner-container`
     || e.target.classList.contains('close')) {
      let container = document.getElementById(`${this.props.type}-form-container`);
      container.style.display = 'none';
      document.querySelector('body').style.overflow =  'scroll';
     }
  }

  setName(e) {
    let state = this.state;
    state.name = e.target.value;
    this.setState(state);
  }

  setEmail(e) {
    let state = this.state;
    state.email = e.target.value;
    this.setState(state);
  }

  setPhone(e) {
    let state = this.state;
    state.phone = e.target.value;
    this.setState(state);
  }

  setAbout(e) {
    let state = this.state;
    state.about = e.target.value;
    this.setState(state);
  }
  render(){

    let preSubmit = <>
      <div className='error'>{this.state.error}</div>
      <form onSubmit={this.submitForm}>
        <label>Your Name</label>
        <input onChange={this.setName} value={this.state.name}/>
        <label>Your Email Address</label>
        <input onChange={this.setEmail} value={this.state.email}/>
        <label>Your Phone Number</label>
        <input onChange={this.setPhone} value={this.state.phone}/>
        {this.props.type === 'consultation' && <>
          <label>Tell Us A Little About Your Case</label>
          <textarea onChange={this.setAbout} value={this.state.about}/>
        </>}
        <input className='button-gold' type='submit' value='SUBMIT'/>
      </form>
    </>;

    let postSubmit = <>
      <p className='submitted'>{this.props.submissionText}</p>
      <input className='button-gold close' onClick={this.closeForm} type='submit' value='GOT IT' />
    </>;


    return (
      <div id={`${this.props.type}-form-container`} onClick={this.checkCloseForm}>
        <div className='form-overlay' style={{maxWidth: this.props.type === 'consultation' ? 800 : 500}}>
          <img className='close' onClick={this.checkCloseForm} src={close} alt='Close form' />
          <div className='title'>
            <img className='scales' src={scale_icon} alt='' />
            <h2>{this.props.title}</h2>
          </div>
          {this.state.submitted ? postSubmit: preSubmit}
          <p className='footer'>{this.props.footerText}</p>
        </div>
      </div>
    );
  }
}

export const ConsultationForm = (props) => {
  let title = <>GET YOUR CONSULTATION</>
  let footerText = <>
    Tell us a little more about your case and we will contact you in 24 hours. <br/>
    We will not share this information with anyone else.
  </>
  let submissionText = 'Thank you for your submission. We will review your case and contact you in 24 hours.'
  return renderClass(DefaultForm, {props: {...props, type: 'consultation', title, footerText, submissionText }})
}

export const SubscriptionForm = (props) => {
  let title = <>START YOUR <strong id='subscription-type'/> SUBSCRIPTION</>
  let footerText = <>Leave us your info we will contact you in 24 hours.</>
  let submissionText = 'Thank you for your submission. We look forward to working with you and will contact you in 24 hours.'
  return renderClass(DefaultForm, {props: {...props, type: 'subscription', title, footerText, submissionText}})
}