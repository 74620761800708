import { IsLarge, IsTablet, IsMobile } from './breakpoints.js';

function SubscriptionsTitle(props) {
  let isTablet = IsTablet();
  let isLarge = IsLarge();
  let isMobile = IsMobile();

  let fontSize = isMobile ? 12 : isTablet ? 16 : isLarge ? 26 : 20;
  let paddingTop = isMobile || isTablet ? 50 : isLarge ? 100 : 75;
  let paddingBottom = isMobile || isTablet ? 500 : isLarge ? 200 : 150;
  let hPadding = isTablet || isMobile ? '5%' : isLarge ? 100 : 75;
  let lineMargin = isMobile || isTablet ? 20 : isLarge ? 40 : 30;
  let maxWidth = 1440;

 
  return (
    <div style={{position: 'relative', textAlign: 'center', fontSize, background: '#090C43', backgroundImage: 'url(/altpattern.png)', backgroundSize: `auto 85%`, paddingTop, paddingBottom, paddingLeft: hPadding, paddingRight: hPadding, backgroundPosition: `center bottom`, backgroundRepeat: 'no-repeat'}}>
      <div className='lora' style={{color: '#E19E39', fontSize: '2em', paddingBottom: '1em', maxWidth, margin: 'auto' }}>
        Business Law Subscriptions
      </div>  
      <div  style={{maxWidth: 1440, marginLeft: 'auto', marginRight: 'auto', color: '#FDDDAD',  paddingBottom: lineMargin, marginBottom: lineMargin}}>
        Monthly Subscription Plans
      </div>
      <div style={{ lineHeight: '1.5em', maxWidth, margin: 'auto'}}>
        {props.subText}
      </div>

    </div>  
      
  );
}

export default SubscriptionsTitle;