import { FormButton } from './ConsultationForm.js';
function Contact(props) {
  return (<>
    <div id='contact-ref' />
    <div id='contact'>
      <h2>Contact</h2>
      <p>
        We offer a no-obligation initial consultation to discuss your legal needs and how we can help.
      </p>
      <FormButton text='CONTACT US' type='consultation' />
    </div>
  </>)
 }

export default Contact;