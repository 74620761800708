import colors from './colors.js';
import { renderClass  } from './breakpoints.js';
import React from 'react';
import { Link } from 'react-router-dom'
import { loadBlogs } from './BlogPage.js';

class BlogWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      blogs: []
    };

    this.loadBlogs = loadBlogs.bind(this);
  }

  async componentDidMount() {
    await this.loadBlogs();
  }

  render() {
    const isLarge = this.props.isLarge;
    let isTablet = this.props.isTablet;
    let isMobile = this.props.isMobile;

    let fontSize = isTablet || isMobile ? 12 : isLarge ? 20 : 14;
    let imageHeight = isTablet || isMobile ? 150 : isLarge ? 250 : 200; 
    let imageWidth = '85%'
    let flexClass = isMobile ? 'flex-col aic' : 'flex-row jcc';
    let background = isTablet || isMobile ? 'rgba(249, 249, 249, 0.78)' : null;

    let blogs = this.state.blogs.filter(blog => (blog.id !== this.props.exclude)).map((blog, i) => {
      if (i >= 3)
        return null;
      let img_url = `https://zora-blog.s3.us-east-2.amazonaws.com/${blog.id}.webp`
      return (
          <div className='blog-link' key={blog.id} style={{width: isMobile ? '80%' : '33%', flexGrow: 'none', margin: '20px 0px', fontSize: '1.1em', fontWeight: 700, lineHeight: '1.5em', textAlign: 'left'}}>
            <Link to={`/blog/${blog.path}`} state={{blog}}>
              <img alt={blog.alt_tag} src={img_url} style={{width: imageWidth, height: imageHeight, objectFit: 'cover', display: 'block', margin: 'auto'}}/>
              <div style={{width: '90%', margin: 'auto', marginTop: 10, textAlign: 'center', }}>{blog.title}</div>
            </Link>
          </div>
        
      );
    })
    return blogs.length ? 
      <div id='blog-widget' style={{padding: '30px 0px', fontSize, overflowX: 'hidden', background: this.props.background || background}}>
        <div style={{textAlign: 'center', color: colors.gold, fontSize: '1.9em'}}>{this.props.title || 'Watson Blog'}</div>
        <div className={flexClass} style={{maxWidth: 1440, margin: 'auto'}}>
          {(this.state.isLoaded && !this.state.error) && blogs}
        </div>
      </div> : null;
  }
  
}

const BlogWidgetComponent = (props) => renderClass(BlogWidget, {props});

export default BlogWidgetComponent