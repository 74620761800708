import { ResponsiveImage } from './breakpoints.js';



function List(props) {
  
  let content = [
    <p>Recognized as one of the <strong>“Top 40 under 40 Attorneys”</strong> by the National Bar Association</p>,
    "Earned her J.D. and M.B.A. degrees at Drake University in Des Moines, IA",
    "Undergraduate degree in Business Management & Finance from Brooklyn College",
    "Licensed in both New York and Georgia."
  ]
  return (
    <div className='list'>
      {content.map((c, i) => (
        <div key={i} className='flex-row aic'>
          <span />
          <div className='roboto'>
            {c}
          </div> 
        </div>
      ))}
    </div>
  )
}

function Bio(props) {
  return (
    <>
      <div id='about' />
      <div id='about-container'>
        <div>
          <div className='column'>
            <h2>About Attorney Watson-Caffe</h2>
            <h3 className='roboto'>Founder of the Practice</h3>
            <div className='content-mobile'>
              <ResponsiveImage 
                mobile='/watson_img_small.webp'
                backup='/watson_img_small.png'
              />
            </div>
            <List />
          </div>
          <div className='content-desktop'>
            <ResponsiveImage 
              mobile=''
              tablet='/watson_img_med.webp, /watson_img_med.png'
              default='/watson_img_med.webp, /watson_img_med.png'
              large='/watson_img_large.webp, /watson_img_large.png'
              backup='/watson_img_large.png'
             />
          </div>
        </div>
      </div> 
    </>
  )
 }

export default Bio;