function PageSubheader(props) { 
  return (
    <div id='page-subheader'>
      <h2>{props.header}</h2>
      <p className='roboto'>
        {props.text}
      </p>
    </div>  
      
  );
}

export default PageSubheader;