import practice_areas1 from './img/practice_areas1.png';
import practice_areas2 from './img/practice_areas2.png';
import practice_areas3 from './img/practice_areas3.png';
import practice_areas4 from './img/practice_areas4.png';
import practice_areas5 from './img/practice_areas5.png';
import practice_areas6 from './img/practice_areas6.png';
import arrow_down from './img/arrow_down.png';
import { TabletOrDefault, ResponsiveImage } from './breakpoints.js';
import React from 'react';
import { Link } from 'react-router-dom';
const classNames = require('classnames');

function indToImg(i) {
  if (i === 0)
    return practice_areas1;
  if (i === 1)
    return practice_areas2;
  if (i === 2)
    return practice_areas3;
  if (i === 3)
    return practice_areas4;
  if (i === 4)
    return practice_areas5;
  if (i === 5)
    return practice_areas6;
}

function List(props) {
  
  let content = [
    {
      title: "MERGERS AND ACQUISITION",
      text: "When your company needs to buy or sell, you need the right legal help. Our mergers and acquisitions services can help with all sorts of transactions, such as company sales and purchases or consolidations and mergers. We'll provide informed advice and represent your company during the process to help you get the deal completed.",
      href: '/mergers-and-acquisitions'
    },
    {
      title: "BUSINESS ORGANIZATIONS",
      text: "Forming a business can be confusing and time-consuming. We can help you with any organization, from a C corporation to an LLC. Our lawyers provide consultation so that your choice of legal structure is for your short-term and long-term goals.  No matter which entity you choose, we will ensure you have the correct paperwork and documents to avoid mistakes and protect your best interests.",
      href: 'business-formation'
    },
    {
      title: "GENERAL BUSINESS LAW",
      text: "As experts in business law, we take care of all your needs, so you don't have to face legal problems alone. We help you with disputes and legal concerns related to contracts, the workplace, employees, real estate, and property. Our priority is to ensure compliance and manage risk for your company.",
      href: 'business-law'
    },
    {
      title: "CONTRACTS",
      text: "We specialize in various contracts, including employment agreements, leasing agreements, franchise agreements, non-compete agreements, and the like.  All contracts are tailored to your needs. We'll prepare and review contracts, so you have all the bases covered.",
      href: 'contracts'
    },
    {
      title: "PARTNERSHIPS",
      text: "We help our clients resolve disputes between shareholders, business partners, LLC members, and joint ventures. Our attorneys counsel and represent you in partnership matters.  We help our clients with preparing legal documents for partnerships. We also help advocate, negotiate, mediate, and resolve disputes with their business partners. We also help with litigation.",
      href: 'partnerships'
    },
    {
      title: "NON-PROFIT LAWYER",
      text: "Nonprofits are a major force for good. But it's not easy to start one from scratch and maintain nonprofit status. Luckily, we've helped our clients with incorporation, IRS compliance, paperwork, and more!  Our law firm has helped clients start and grow organizations that do good in the world.  We have helped our clients in the past with the incorporation and obtaining 501(c) status. No matter what you need, we can assist with paperwork, legal forms, and guidance on complying and maintaining your nonprofit status.",
      href: 'non-profit'
    }
  ];
  
  return (<>
    {content.map((c, i) => (
      <React.Fragment key={i}>
      <div className='flex-row aic roboto li' onClick={() => props.setIndex(i)}>
        <TabletOrDefault>
          <img src={indToImg(i)} alt='' />
        </TabletOrDefault>
        <h4>
          {c.title}
        </h4> 
        <img className='expand' src={arrow_down} alt='expand content'/>
      </div>
      <div className={classNames({ 'expandable': true, 'open': props.openIndex === i,})}>
        <p>
          {c.text}
        </p>
        <div>
          <Link to={c.href}><span/> &nbsp;&nbsp;LEARN MORE</Link>
        </div>
      </div>
      </ React.Fragment>
    ))}
  </>)
}

class PracticeAreas extends React.Component {
  constructor(props){
    super(props);
    this.state = { openIndex: -1 };
    //this.setIndex = this.setIndex.bind(this);
  }

  setIndex = (i) => {
    if (i === this.state.openIndex)
      this.setState({openIndex: -1})
    else
      this.setState({openIndex: i})
  }

  render(){ 
    return (<>
      <div id='business-law'/>
      <div id='business-law-container'>
        <div className='flex-col aic jcc'>
          <ResponsiveImage className='background-img'
          mobile='/landing_img_cropped_xs.webp, /landing_img_cropped_xs.png'
          tablet='/landing_img_cropped_small.webp, /landing_img_cropped_small.png'
          default='/landing_img_cropped_med.webp, /landing_img_cropped_med.png'
          large='/landing_img_cropped_large.webp, /landing_img_cropped_large.png'
          backup='/landing_img_cropped_large.png'
          />
          <h2>Practice Areas</h2>
          <List openIndex={this.state.openIndex} setIndex={this.setIndex}/>
        </div> 
      </div>
    </>)
  }
}

export default PracticeAreas