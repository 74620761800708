import { ResponsiveImage} from './breakpoints.js';
import { FormButton } from './ConsultationForm';

function PageIllustration(props) { 
  return (
    <div id='illustration'>
      <div>
        <ResponsiveImage 
          mobile={`${props.image}.webp`}
          tablet={`${props.image}.webp`}
          default={`${props.image}.webp`}
          large={`${props.image}.webp`}
          backup={`${props.image}.png`}
        />
        <div className='text-container'>
          <h2>{props.header}</h2>
          <p className='roboto'>{props.text}</p>
          <FormButton type='consultation' text='SCHEDULE A CONSULTATION' />
        </div>
      </div>  
    </div>
      
  );
}

export default PageIllustration;