import scales_gold from './img/scales_gold.png';
import colors from './colors';
import { IsTablet, IsMobile, IsLarge } from './breakpoints.js';

let columns = [
  [
    "Business organization",
    "Buy-Sell Agreements",
    "Debt Workouts",
    "Dissolutions",
    "Employment Agreements",
    "Franchise Agreements"
  ],
  [
    "Lease Agreements",
    "Licensing Agreements",
    "Mergers and Acquisitions",
    "Non-compete Agreements",
    "Stock Purchase Agreements",
    "Drafting organization documentation"
  ]
]


function List(props) {
  const isLarge = IsLarge();
  const isMobile = IsMobile();
  const isTablet = IsTablet();
  let columnWidth = isMobile || isTablet ? '50%' : isLarge ? 400 : 325;
  let paddingLeft = isMobile || isTablet ? 0 : isLarge ? 60 : 40
  return props.columns.map((content, i) => 
    <div key={i} className='flex-col jcsa' style={{width: columnWidth}}>
      {content.map((c, j) => (
        <div key={j} className='flex-row aic' style={{marginTop: '1.5em', paddingLeft: (i === 0) && paddingLeft}}>
          <img src={scales_gold} style={{width: '1.5em', paddingRight: '1.5em', }} alt='' />
          <div className='roboto'>
            {c}
          </div> 
        </div>
      ))}
    </div>
  );
}

function SubscriptionServices(props) {
  const isLarge = IsLarge();
  let isTablet = IsTablet(); 
  let isMobile = IsMobile();

  let flexClass = isTablet || isMobile ? 'flex-col' : 'flex-row';
  let fontSize = isMobile ? 12 : isLarge ? 20 : 16;
  let vPadding = isMobile ? 30 : isLarge ? 60 : 40
  return (
    <>
      <div style={{background: '#FFF6DE', padding: vPadding + 'px 0px', fontSize, overflowX: 'hidden', }}>
        <div style={{textAlign: 'center', color: colors.gold, fontSize: '1.5em'}}>A La Carte Business Services Offered at Watson Law LLC</div>
        <div className={`${flexClass} aic jcc roboto`} style={{maxWidth: 1440, margin: 'auto'}}>            
            <List columns={columns} />
        </div>
      </div> 
    </>
  )
 }

export default SubscriptionServices;