import menu from './img/hamburger.png';
import close from './img/close.png';
import { renderClass, ResponsiveImage } from './breakpoints.js';
import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { FormButton } from './ConsultationForm';
import mobile_icon from './img/phone_icon.png';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hamburgerOpen: false }
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({hamburgerOpen: !this.state.hamburgerOpen});
  }

  scrollTo(anchor) {
    if (window.location.pathname === '/'){
      // If we're on the homepage, just scroll
      let offset = 0;
      if (anchor)
        offset = document.getElementById(anchor).offsetTop;
      window.scroll({top: offset, behavior: 'smooth'})
    } else {
      // Otherwise, navigate to page
      if (anchor)
        window.location.href = '/#' + anchor + '_';
      else 
        window.location.href = '/'
    }
  }

  render() {
    let isHomePage = window.location.pathname === '/';

    return (<>
      <div id='header-background' />
      <div id='header-container' className={isHomePage ? 'home-page' : ''}>
        <div id='header' className={isHomePage ? 'home-page' : ''}>
          <Link to=''>
            <ResponsiveImage id='logo' alt='Watson Law LLC Logo'
            mobile='/logo_med.webp'
            tablet='/logo_med.webp'
            default='/logo_med.webp'
            large='/logo_large.webp'
            backup='/logo_large.png'
            />
          </Link>
          <div id='header-links' className='flex-row aic'>
            <span id='home-menu-link' onClick={() => this.scrollTo('')}>Home</span>
            <span id='about-us-menu-link' onClick={() => this.scrollTo('about')}>About Us</span>
            <a id='blog-menu-link' href='/blog'>Blog</a>
            <span id='contact-menu-link' onClick={() => this.scrollTo('contact-ref')}>Contact</span>
            <div className='dropdown'>
              <span id='business-law-menu-link'>Business Law <img width='15' src='/caret_down.png' alt='dropdown menu'/></span>
              <div className='dropdown-content'>
                <a href='/mergers-and-acquisitions'>Mergers And Acquisitions</a>
                <a href='/business-formation'>Business Organization</a>
                <a href='/business-law'>General Business Law</a>
                <a href='/contracts'>Contracts</a>
                <a href='/partnerships'>Partnerships</a>
                <a href='/non-profit'>Non-Profit Lawyer</a>
                <a href='/subscriptions'>Business Law Subscriptions</a>
              </div>
            </div>
          </div>
          <div className='sep' />
          <a className='phone-number' href="tel:404-600-1771" ><img src={mobile_icon} alt='Phone icon'/>404-600-1771</a>
          <FormButton type='consultation' style={{flexGrow: 0, marginLeft: 'auto', fontSize: 13}} text={'REQUEST A CONSULTATION'} />
          <div id='hamburger-icon'> 
              <img onClick={this.toggleMenu} src={menu} alt='Hamburger Menu' />
          </div>
        </div>
        <div className='phone-row'>
        <a className='phone-number' href="tel:404-600-1771" ><img src={mobile_icon} alt='Phone icon'/>404-600-1771</a>
        </div>
      </div>
      <div id='hamburger-menu' className={`flex-col aic ${this.state.hamburgerOpen ? 'open' : ''}`}>
        <img alt='Navigation menu icon' src={close} onClick={this.toggleMenu}/>
        <span className='hamburger-item' id='home-menu-link' onClick={() => {this.toggleMenu(); this.scrollTo('')}}>Home</span>
        <span className='hamburger-item' id='about-us-menu-link' onClick={() => {this.toggleMenu(); this.scrollTo('about')}}>About Us</span>
        <a className='hamburger-item' id='blog-menu-link' href='/blog'>Blog</a>
        <span className='hamburger-item' id='contact-menu-link' onClick={() => {this.toggleMenu(); this.scrollTo('contact-ref')}}>Contact</span>
        <a className='hamburger-item' href='/mergers-and-acquisitions'>Mergers And Acquisitions</a>
        <a className='hamburger-item' href='/business-formation'>Business Organization</a>
        <a className='hamburger-item' href='/business-law'>General Business Law</a>
        <a className='hamburger-item' href='/contracts'>Contracts</a>
        <a className='hamburger-item' href='/partnerships'>Partnerships</a>
        <a className='hamburger-item' href='/non-profit'>Non-Profit Lawyer</a>
        <a className='hamburger-item' href='/subscriptions'>Business Law Subscriptions</a>
      </div>  
    </>);
  }
}

const HeaderComponent = (props) => {let location = useLocation(); props = {...props, location}; return renderClass(Header, {props});}
export default HeaderComponent